var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:{ 'pattern-bg': _vm.light, 'pattern-bg-dark': _vm.dark }},[(_vm.menu != 'none')?_c('v-navigation-drawer',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"elevation-2",attrs:{"mini-variant":_vm.mini,"app":"","clipped":"","expand-on-hover":_vm.$vuetify.breakpoint.mdAndUp},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-divider'),_c('v-list',{attrs:{"nav":"","width":"100%"}},[(_vm.vuePainel)?_c('v-list-item',{attrs:{"to":{ name: 'PainelEventos' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc("pages.painel")))])],1)],1):_vm._e()],1)]},proxy:true}],null,false,2496708145),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.menu === 'dashboard')?_c('MenuDashBoard'):_vm._e(),(_vm.menu === 'sistema')?_c('MenuSistema'):_vm._e(),(_vm.menu === 'estoque')?_c('MenuEstoque'):_vm._e(),(_vm.menu === 'configuracoes')?_c('MenuConfiguracoes'):_vm._e(),(_vm.menu === 'superadmin')?_c('MenuSuperAdmin'):_vm._e(),(_vm.menu === 'relatorios')?_c('MenuRelatorios'):_vm._e()],1):_vm._e(),_c('v-app-bar',{staticClass:"elevation-2",class:{ 'panel-gradient-bg': _vm.light, 'panel-gradient-bg-dark': _vm.dark },attrs:{"clipped-left":"","app":"","dark":""}},[_c('v-app-bar-nav-icon',{staticClass:"d-lg-none d-xl-none",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('BreadCrumbs'),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.dialogProfile = true}}},'v-btn',attrs,false),on),[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"35"}},[_c('img',{attrs:{"src":_vm.avatar
                  ? _vm.apiUrl + _vm.avatar
                  : require('@/assets/avatar_placeholder.png')}})]),_c('span',{staticClass:"text-subtitle-1 mr-1",staticStyle:{"letter-spacing":"0.0892857143em !important"}},[_vm._v(" "+_vm._s(_vm.nome.toUpperCase())+" ")])],1)]}}])},[_c('span',[_vm._v("Profile")])]),(_vm.super_admin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","to":{ name: 'Configs' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("admin_panel_settings")])],1)]}}],null,false,1349935153)},[_c('span',[_vm._v("Super Admin")])]):_vm._e(),(_vm.vueSistema)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","to":{ name: 'Usuarios' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("build_circle")])],1)]}}],null,false,833617146)},[_c('span',[_vm._v("Sistema")])]):_vm._e(),(_vm.vueVerConfiguracoes)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","to":{ name: 'Tags' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("settings")])],1)]}}],null,false,2391653808)},[_c('span',[_vm._v(_vm._s(_vm.$tc("global.configuracao", 2)))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.deslogarUsuario($event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("exit_to_app")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$tc("global.sair")))])])],1),_c('v-main',[_c('transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1),_c('v-footer',{staticClass:"elevation-2",class:{ 'panel-gradient-bg': _vm.light, 'panel-gradient-bg-dark': _vm.dark },attrs:{"dark":"","app":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"body-2"},[_vm._v(" SOFTPAR "),_c('span',{staticClass:"caption mb-2"},[_vm._v("®")]),_vm._v(" - "+_vm._s(_vm.year)+" ")]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","top":"","min-width":"150px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-0",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("language")])],1)]}}])},[_c('LocaleSwitcher')],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.setTemplate}},[_c('v-icon',[_vm._v("invert_colors")])],1)],1),_c('Profile',{attrs:{"dialogProfile":_vm.dialogProfile},on:{"update:dialogProfile":function($event){_vm.dialogProfile=$event},"update:dialog-profile":function($event){_vm.dialogProfile=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }