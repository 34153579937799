export default {
  methods: {
    deslogarUsuario() {
      window.localStorage.removeItem("last_route");
      this.$store.commit("Usuario/UPDATE_AUTO_REDIRECT", false);
      this.$store.dispatch("Usuario/deslogarUsuario").then(response => {
        this.$router.push("/login");
      });
    }
  }
};
