<template>
  <v-app :class="{ 'pattern-bg': light, 'pattern-bg-dark': dark }">
    <v-navigation-drawer
      v-resize="onResize"
      v-model="drawer"
      :mini-variant.sync="mini"
      app
      class="elevation-2"
      clipped
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
      v-if="menu != 'none'"
    >
      <MenuDashBoard v-if="menu === 'dashboard'" />
      <MenuSistema v-if="menu === 'sistema'" />
      <MenuEstoque v-if="menu === 'estoque'" />
      <MenuConfiguracoes v-if="menu === 'configuracoes'" />
      <MenuSuperAdmin v-if="menu === 'superadmin'" />
      <MenuRelatorios v-if="menu === 'relatorios'" />

      <template v-slot:append>
        <v-divider></v-divider>
        <v-list nav width="100%">
          <v-list-item v-if="vuePainel" :to="{ name: 'PainelEventos' }">
            <v-list-item-action>
              <v-icon>dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $tc("pages.painel") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      clipped-left
      class="elevation-2"
      :class="{ 'panel-gradient-bg': light, 'panel-gradient-bg-dark': dark }"
      app
      dark
    >
      <v-app-bar-nav-icon
        class="d-lg-none d-xl-none"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <BreadCrumbs />
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text @click="dialogProfile = true" v-bind="attrs" v-on="on">
            <v-avatar size="35" class="mr-2">
              <img
                :src="
                  avatar
                    ? apiUrl + avatar
                    : require('@/assets/avatar_placeholder.png')
                "
              />
            </v-avatar>
            <span
              style="letter-spacing: 0.0892857143em !important;"
              class="text-subtitle-1 mr-1"
            >
              {{ nome.toUpperCase() }}
            </span>
          </v-btn>
        </template>
        <span>Profile</span>
      </v-tooltip>

      <v-tooltip bottom v-if="super_admin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :to="{ name: 'Configs' }">
            <v-icon>admin_panel_settings</v-icon>
          </v-btn>
        </template>
        <span>Super Admin</span>
      </v-tooltip>

      <v-tooltip bottom v-if="vueSistema">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :to="{ name: 'Usuarios' }">
            <v-icon>build_circle</v-icon>
          </v-btn>
        </template>
        <span>Sistema</span>
      </v-tooltip>

      <v-tooltip bottom v-if="vueVerConfiguracoes">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" :to="{ name: 'Tags' }">
            <v-icon>settings</v-icon>
          </v-btn>
        </template>
        <span>{{ $tc("global.configuracao", 2) }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
            @click.prevent="deslogarUsuario"
          >
            <v-icon>exit_to_app</v-icon>
          </v-btn>
        </template>
        <span>{{ $tc("global.sair") }}</span>
      </v-tooltip>
    </v-app-bar>

    <v-main>
      <transition mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <v-footer
      class="elevation-2"
      dark
      :class="{ 'panel-gradient-bg': light, 'panel-gradient-bg-dark': dark }"
      app
    >
      <!-- Softpar -->
      <div class="body-2" v-if="$vuetify.breakpoint.mdAndUp">
        SOFTPAR <span class="caption mb-2">&reg;</span> - {{ year }}
      </div>
      <v-divider
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mx-4"
        vertical
      ></v-divider>

      <!-- Idiomas -->
      <v-menu offset-y top min-width="150px">
        <template v-slot:activator="{ on }">
          <v-btn class="pa-0" icon v-on="on">
            <v-icon>language</v-icon>
          </v-btn>
        </template>
        <LocaleSwitcher />
      </v-menu>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <!-- Light/Dark Mode -->
      <v-btn @click="setTemplate" icon>
        <v-icon>invert_colors</v-icon>
      </v-btn>
    </v-footer>

    <Profile :dialogProfile.sync="dialogProfile" />
  </v-app>
</template>

<script>
import AppLogOut from "@/mixins/appLogOut.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "AppLayout",
  components: {
    LocaleSwitcher: () => import("./components/LocaleSwitcher.vue"),
    Profile: () => import("@/views/dashboard/profile/Profile.vue"),
    MenuDashBoard: () => import("./menus/MenuDashBoard"),
    MenuSistema: () => import("./menus/MenuSistema"),
    MenuEstoque: () => import("./menus/MenuEstoque"),
    MenuConfiguracoes: () => import("./menus/MenuConfiguracoes"),
    MenuSuperAdmin: () => import("./menus/MenuSuperAdmin"),
    MenuRelatorios: () => import("./menus/MenuRelatorios"),
  },
  mixins: [AppLogOut],
  data: () => ({
    drawer: null,
    mini: true,
    locale: "",
    windowSize: {
      x: 0,
      y: 0,
    },
    dialog: false,
    apiUrl: null,
    dialogProfile: false,
    menu: "",
  }),
  computed: {
    year() {
      return this.$moment().year();
    },
    nome() {
      let splitName = this.$store.state.Usuario.usuario.name.split(/ /);
      let firstName = splitName[0] ? splitName[0] : "";
      let lastName = splitName[1] ? splitName[1] : "";
      let name = `${firstName} ${lastName}`;
      return name;
    },
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    ...mapState("Usuario", {
      avatar: (state) => state.usuario.avatar,
      super_admin: (state) => state.usuario.super_admin,
    }),
    ...mapGetters("Componentes", ["getAccess"]),
    vueVerConfiguracoes() {
      return this.getAccess("vueConfiguracoes", "menu");
    },
    vuePainel() {
      return this.getAccess("vuePainel", "menu");
    },
    vueSistema() {
      return this.getAccess("vueSistema", "menu");
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.meta.menu) {
        this.menu = this.$route.meta.menu;
      }
    },
  },
  methods: {
    setTemplate() {
      if (this.light) {
        this.$store.commit("UPDATE_TEMPLATE", { dark: true, light: false });
        localStorage.setItem("template", "dark");
        this.$vuetify.theme.dark = true;
      } else {
        this.$store.commit("UPDATE_TEMPLATE", { dark: false, light: true });
        localStorage.setItem("template", "light");
        this.$vuetify.theme.dark = false;
      }
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      if (this.windowSize.x < 1264) {
        return (this.mini = false);
      }
    },
  },
  mounted() {
    if (this.$route.meta.menu) {
      this.menu = this.$route.meta.menu;
    } else {
      this.menu = "dashboard";
    }
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
    this.onResize();
    this.locale = this.$i18n.locale;
  },
};
</script>

<style lang="scss" scoped>
.pattern-bg {
  background-color: #eeeeee !important;
  // background-image: url("../assets/pattern.svg") !important;
  // background-position: center !important;
  // background-repeat: no-repeat !important;
  // background-size: 100% !important;
}

.pattern-bg-dark {
  background-color: #3a3a3a !important;
  // background-image: url("../assets/pattern_dark.svg") !important;
  // background-position: center !important;
  // background-repeat: no-repeat !important;
  // background-size: 100% !important;
}

.v-enter,
.v-leave-to {
  transform: translate3d(-20px, 0, 0);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
